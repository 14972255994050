import React from 'react';
//import { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import About from './pages/About';
import Invest from './pages/Invest';
import SignUp from './pages/SignUp';
import LoginPage from './pages/LoginPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Dashboard from './pages/Dashboard';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
//import { AuthContext } from './components/AuthContext';


const App = () => {
  //const { currentUser, hasPositionsSaved } = useContext(AuthContext);

  return (
    <Router>
      <Navbar />
      <Routes>
      <Route path="/" element={<Dashboard />} />
        <Route path="/home" element={<Dashboard />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/post/:slug" element={<BlogPost />} />
        <Route path="/about" element={<About />} />
        <Route path="/calculator" element={<Home />} />
        <Route path="/invest" element={<Invest />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/sign_up" element={<SignUp />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
