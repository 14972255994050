// OptionPositionsTable.js
import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import './TableStyles.css';


const OptionPositionsTable = ({ data, title }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Symbol',
        accessor: 'stockSymbol',
      },
      {
        Header: 'Strike',
        accessor: 'optionSymbol',
        Cell: ({ value }) => {
            // Extract the strike price using the function
            const getStrikePriceFromOptionSymbol = (optionSymbol) => {
              const strikePricePart = optionSymbol.slice(-8);
              const formattedStrikePrice = parseFloat(strikePricePart.slice(0, -3) + '.' + strikePricePart.slice(-3));
              return `$${formattedStrikePrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            };
        
            // Call the function and return the strike price
            return getStrikePriceFromOptionSymbol(value);
          },
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',  // Use the quantityProp to access the correct property
      },
      {
        Header: 'Current Price',
        accessor: 'currentPrice',
        Cell: ({ value }) => {
          // Check if value is not null or undefined
          if (value !== null && value !== undefined) {
            return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
          }
          // Return a default string or format when value is null or undefined
          return 'N/A'; // or any other placeholder you prefer
        },
      },
      {
        Header: 'Value',
        accessor: (row) => row.currentPrice * row.quantity * 100,
        Cell: ({ value }) => `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,  // This line formats the currentPrice value
      },
      {
        Header: 'Unit Cost',
        accessor: 'purchasePrice',
        Cell: ({ value }) => `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,  // This line formats the currentPrice value
      },
      {
        Header: 'Cost Basis',
        accessor: 'totalPurchasePrice',
        Cell: ({ value }) => `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,  // This line formats the currentPrice value
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'Value',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );

  return (
    <div>
      <h3>{title}</h3>
      <table {...getTableProps()} className="positions-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ borderBottom: 'solid 3px black', background: 'aliceblue', color: 'black', fontWeight: 'bold', }}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : '↕︎'}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} style={{ padding: '8px', border: 'solid 1px gray' }}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

};

export default OptionPositionsTable;