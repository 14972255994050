class StockItem {
    constructor(date, value) {
        this.date = date;
        this.value = value;
    }
}

const convertData = (jsonData) => {
    const stockItems = [];

    for (const json of jsonData) {
        const date = new Date(json.date);
        const userTimezoneOffset = date.getTimezoneOffset() * 210000; 
        const item = new StockItem(new Date(date.getTime() + userTimezoneOffset), json.value);
        stockItems.push(item);
    }

    return stockItems;
}

export { StockItem, convertData };
