// CustomButton.js
import React from 'react';
import './CustomButton.css'; // Import your CSS file

const CustomButton = ({ children, onClick, disabled, style }) => {
  // Combine predefined styles with style prop
  const combinedStyles = { ...style, ...(disabled && { backgroundColor: 'gray' }) };

  return (
    <button
      className={`custom-button ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      disabled={disabled}
      style={combinedStyles}
    >
      {children}
    </button>
  );
};

export default CustomButton;