import React, { useState, useContext } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { AuthContext } from './AuthContext';
import './AuthStyles.css'


function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const { setIsNewAccountRegistered } = useContext(AuthContext);


    const handleRegister = async (event) => {
        event.preventDefault();
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            setIsNewAccountRegistered(true);
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <form onSubmit={handleRegister} className="auth-form">
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            {error && <div className="auth-error">{error}</div>}
            <button type="submit">Sign up</button>
        </form>
    );
}

export default Register;
