import React, { useState, useEffect } from 'react';
import logo from './assets/Incomewithoptions.png'
import burger_menu from './assets/burger_menu.png'
import burger_close from './assets/burger_close.png'
import './Navbar.css'

const Navbar = () => {
    const [showLinks, setShowLinks] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 950);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 950);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <nav className="navbar">
            <div className="container">
                <div className="logo"><a href="https://incomewithoptions.com"><img src={logo} alt="Logo" className="logo-img" /></a></div>
                {isMobileView && (
                    <>
                        <div className="hamburger-menu" onClick={() => setShowLinks(true)}><img src={burger_menu} alt="Menu" /></div>
                        {showLinks && (
                            <div className="full-screen-menu">
                                <img src={burger_close} alt="Close" className="close-hamburger" onClick={() => setShowLinks(false)}/>
                                <div className="menu-nav-links">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/blog">Blog</a></li>
                                    <li><a href="/about">About</a></li>
                                    <li><a href="/invest">Invest</a></li>
                                </ul>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {!isMobileView && (
                    <ul className="hidden-ul">
                        <li><a className="nav-link home" href="/">Home</a></li>
                        <li><a className="nav-link about_link" href="/blog">Blog</a></li>
                        <li><a className="nav-link about_link" href="/about">About</a></li>
                        <li><a className="nav-link invest" href="/invest">Invest</a></li>
                    </ul>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
