import React from 'react';
import Login from '../components/Login';
import Register from '../components/Register';
import LogoutButton from '../components/LogoutButton';
import GoogleSignIn from '../components/GoogleSignIn';
import { AuthContext } from '../components/AuthContext';

class LoginPage extends React.Component {
  render() {
    return (
      <div>
        <main>
          <AuthContext.Consumer>
            {({ currentUser }) => (
              <section className="auth-section">
                {currentUser ? (
                  <>
                    <LogoutButton />
                    <div>Welcome, {currentUser.email}</div>
                  </>
                ) : (
                  <>
                    <Login />
                    <Register />
                    <GoogleSignIn />
                  </>
                )}
              </section>
            )}
          </AuthContext.Consumer>
        </main>
      </div>
    );
  }
}

export default LoginPage;

