import React from 'react';

class HubspotForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '40691883',
          formId: 'b3596b9f-9343-4b41-98af-f9d03fdf6b6f',
          target: '#hubspotForm'
        })
      }
    });
  }

  render() {
    return (
      <div id="hubspotForm"></div>
    );
  }
}

export default HubspotForm;