import React from 'react';
import './Invest.css'
import HubspotForm from '../components/HubspotForm'


const Invest = () => {
    return (
        <div className="Invest">
                <h1 className="h-1">Invest</h1>
                <div className="overlap">
                    <p className="overlap_text_invest">
                        <ul>
                            <li>Tired of managing all your trades manually each month?</li>
                            <li>Want to increase your diversification across the entire index?</li>
                        </ul>
                        Fill out the contact form below to receive more information about Concept IV Capital, Inc.
                        Our minimum account size is $1 million.
                    </p>
                    <div className="grid-Container">
                        <HubspotForm />
                    </div>
                </div>
        </div>
    );
}

export default Invest;