import React from 'react';
import { IgrFinancialChart } from "igniteui-react-charts";
import { IgrFinancialChartModule } from "igniteui-react-charts";

IgrFinancialChartModule.register();


export default function PortfolioLineChart({ data }) {
    console.log("Data passed to chart:", data);
    // Ensure data is in the correct format for IgrFinancialChart
    // You may need to transform your Firestore data to match the expected format

    return (

        <div className="chart-container">
            <IgrFinancialChart
                width="100%"
                height="100%"
                chartType="Line"
                thickness={4}
                yAxisMode="Numeric"
                dataSource={data}
                isToolbarVisible={false}
                zoomSliderType="None"
                brushes={['#1351d5','#FFA500', '#000000', '#4D8C7B']} // Sets the color of the line
                yAxisMajorStroke="transparent" // Removes horizontal grid lines
                xAxisMajorStroke="transparent" // Removes vertical grid lines, if desired
                chartTitle="" // Removes the chart title
                subtitle="" // Removes the chart subtitle
                actualXAxisLabelTextColor="black"
                xAxisLabelBottomMargin={10}
                dataToolTipSummaryType="None"
            />
        </div>
    );
}
