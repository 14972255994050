import React from 'react';
import Register from '../components/Register';
import LogoutButton from '../components/LogoutButton';
//import GoogleSignIn from '../components/GoogleSignIn';
import { AuthContext } from '../components/AuthContext';

class SignUp extends React.Component {
  render() {
    return (
      <div>
        <main>
          <AuthContext.Consumer>
            {({ currentUser }) => (
              <section className="auth-section">
                {currentUser ? (
                  <>
                    <LogoutButton />
                    <div>Welcome, {currentUser.email}</div>
                  </>
                ) : (
                  <>
                    <Register />
                  </>
                )}
              </section>
            )}
          </AuthContext.Consumer>
        </main>
      </div>
    );
  }
}

export default SignUp;

