import React, { useEffect, useState, useContext } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { AuthContext } from "../components/AuthContext";
import { FirestoreContext } from "../components/FirestoreContext";
import StockPositionsTable from "../components/StockPositionsTable";
import OptionPositionsTable from "../components/OptionPositionsTable";
import PortfolioLineChart from "../components/PortfolioLineChart";
import { convertData } from "../utils/chartHelper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";

const Dashboard = () => {
  const { currentUser } = useContext(AuthContext);
  const [portfolio, setPortfolio] = useState(null);
  const [stockPositions, setStockPositions] = useState([]);
  const [optionPositions, setOptionPositions] = useState([]);
  const db = useContext(FirestoreContext);
  //const [currentStockPrices, setCurrentStockPrices] = useState({});
  //const [currentOptionPrices, setCurrentOptionPrices] = useState({});
  const [currentPortfolioValue, setCurrentPortfolioValue] = useState(
    "Loading..."
  );
  const [mergedStockData, setMergedStockData] = useState([]);
  const [mergedOptionData, setMergedOptionData] = useState([]);
  const [historicalValues, setHistoricalValues] = useState([]);
  const [chartData, setChartData] = useState([]);
  //const [currentCashBalance, setCurrentCashBalance] = useState(null);
  const [compareWithSPY, setCompareWithSPY] = useState(false);
  const [compareWithJEPI, setCompareWithJEPI] = useState(false);
  const [compareWithQYLD, setCompareWithQYLD] = useState(false);
  const [spyData, setSpyData] = useState([]);
  const [jepiData, setJepiData] = useState([]);
  const [qyldData, setQyldData] = useState([]);

  const [previousDayValue, setPreviousDayValue] = useState(null);
  const [
    dollarDifferenceFromPreviousDay,
    setDollarDifferenceFromPreviousDay,
  ] = useState(null);
  const [
    percentDifferenceFromPreviousDay,
    setPercentDifferenceFromPreviousDay,
  ] = useState(null);

  const fetchCurrentPrices = async () => {
    try {
      const stockSymbols = stockPositions
        .map((stockPosition) => stockPosition.stockSymbol)
        .join(",");
      const optionSymbols = optionPositions
        .map((optionPosition) => optionPosition.optionSymbol)
        .join(",");

      const fetchStocks = fetch(
        `https://us-central1-income-with-options.cloudfunctions.net/api/fetchCurrentStockPrices?symbols=${stockSymbols}`
      );
      const fetchOptions = fetch(
        `https://us-central1-income-with-options.cloudfunctions.net/api/fetchCurrentOptionPrices?symbols=${optionSymbols}`
      );

      //const fetchStocks = fetch(`http://localhost:5000/fetchCurrentStockPrices?symbols=${stockSymbols}`);
      //const fetchOptions = fetch(`http://localhost:5000/fetchCurrentOptionPrices?symbols=${optionSymbols}`);

      const [stockResponse, optionResponse] = await Promise.all([
        fetchStocks,
        fetchOptions,
      ]);

      const stockPrices = await stockResponse.json();
      const optionPrices = await optionResponse.json();

      //setCurrentStockPrices(stockPrices);
      //setCurrentOptionPrices(optionPrices);

      await mergeStockData(stockPrices);
      await mergeOptionData(optionPrices);
    } catch (error) {
      console.error("Error fetching current stock prices:", error);
    }
  };

  const calculateCurrentPortfolioValue = async () => {
    try {
      // Sum the value of stock positions
      const totalStockValue = mergedStockData.reduce((acc, stockPosition) => {
        return acc + stockPosition.currentPrice * stockPosition.quantity;
      }, 0);
      console.log("TOTAL STOCK VALUE" + totalStockValue);
      // Sum the value of option positions
      const totalOptionValue = mergedOptionData.reduce(
        (acc, optionPosition) => {
          return (
            acc + optionPosition.currentPrice * optionPosition.quantity * 100
          );
        },
        0
      );
      console.log("TOTAL OPTION VALUE" + totalOptionValue);
      // Fetch the cash balance from Firestore using a query
      const userId = currentUser
        ? currentUser.uid
        : "QzjwCO39HKbRJzVr04CnWEuvMf72";
      const portfoliosCollection = collection(db, "portfolios");
      const q = query(portfoliosCollection, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      // Assume the first document returned (if any) is the correct portfolio
      const portfolioDoc = querySnapshot.docs[0];
      if (!portfolioDoc) {
        throw new Error("No portfolio found for the current user");
      }

      const cashBalance = portfolioDoc.data().cashBalance;

      // Sum everything to get the current portfolio value
      const currentPortfolioValue =
        totalStockValue + totalOptionValue + cashBalance;
      console.log("CASH BALANCE" + cashBalance);
      // Set the current portfolio value in your state (assuming you have a state setter function)
      setCurrentPortfolioValue(currentPortfolioValue);
      //setCurrentCashBalance(cashBalance);
    } catch (error) {
      console.error("Error calculating current portfolio value:", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = currentUser
        ? currentUser.uid
        : "QzjwCO39HKbRJzVr04CnWEuvMf72";
      const q = query(
        collection(db, "portfolios"),
        where("userId", "==", userId)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        setPortfolio(doc.data());

        //Fetch stock positions
        const stockPositionsRef = collection(doc.ref, "stockPositions");
        const stockPositionsSnapshot = await getDocs(stockPositionsRef);
        const stockPositionsData = stockPositionsSnapshot.docs.map((doc) =>
          doc.data()
        );
        setStockPositions(stockPositionsData);

        //Fetch option positions
        const optionPositionsRef = collection(doc.ref, "optionPositions");
        const optionPositionsSnapshot = await getDocs(optionPositionsRef);
        const optionPositionsData = optionPositionsSnapshot.docs.map((doc) =>
          doc.data()
        );
        setOptionPositions(optionPositionsData);
        console.log(optionPositionsData);

        //Fetch historical values
        const historicalValuesRef = collection(doc.ref, "historicalValues");
        const historicalValuesSnapshot = await getDocs(historicalValuesRef);
        const tmpHistoricalValues = historicalValuesSnapshot.docs.map((doc) => {
          const recordedAt = doc.data().recorded_at.toDate();
          const formattedDate = recordedAt.toLocaleDateString("en-CA"); // Canadian locale uses YYYY-MM-DD format
          return {
            ...doc.data(),
            date: formattedDate,
          };
        });
        const historicalValuesData = convertData(tmpHistoricalValues);
        historicalValuesData.__dataIntents = {
          value: ["SeriesTitle/My Portfolio"],
        };
        setHistoricalValues(historicalValuesData);

        //Get yesterday's value (and current day's close if after-hours)
        const todayString = getTodayDateString();
        if (tmpHistoricalValues.length > 0) {
          const lastEntry = tmpHistoricalValues[tmpHistoricalValues.length - 1];
          if (
            lastEntry.date === todayString &&
            tmpHistoricalValues.length > 1
          ) {
            // If the last entry is today's date, use the second last entry
            setPreviousDayValue(
              tmpHistoricalValues[tmpHistoricalValues.length - 2].value
            );
          } else {
            // Otherwise, use the last entry
            setPreviousDayValue(lastEntry.value);
          }
        }
      });
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const getTodayDateString = () => {
    const now = new Date(); // Current UTC date and time
    const offsetInHours = 6; // Central US Time is UTC-6
    const centralUSTime = new Date(
      now.getTime() - offsetInHours * 60 * 60 * 1000
    ); // Subtracting the offset
    return centralUSTime.toISOString().split("T")[0]; // Formatting as 'YYYY-MM-DD'
  };

  const mergeStockData = (newStockPrices) => {
    const merged = stockPositions.map((stockPosition) => {
      const currentPrice = newStockPrices[stockPosition.stockSymbol];
      return {
        ...stockPosition,
        currentPrice: currentPrice || null,
      };
    });
    setMergedStockData(merged);
  };

  const mergeOptionData = (newOptionPrices) => {
    const merged = optionPositions.map((optionPosition) => {
      const currentPrice = newOptionPrices[optionPosition.stockSymbol];

      return {
        ...optionPosition,
        currentPrice: currentPrice || null,
      };
    });
    setMergedOptionData(merged);
  };

  useEffect(() => {
    if (stockPositions.length > 0 && optionPositions.length > 0) {
      fetchCurrentPrices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockPositions, optionPositions]);

  useEffect(() => {
    if (mergedStockData.length > 0 && mergedOptionData.length > 0) {
      calculateCurrentPortfolioValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedStockData, mergedOptionData]);

  useEffect(() => {
    if (compareWithSPY) {
      fetchBenchmarkData("SPY", setSpyData);
    } else {
      setSpyData([]); // Clear the data if unchecked
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareWithSPY, currentUser]);

  useEffect(() => {
    if (compareWithJEPI) {
      fetchBenchmarkData("JEPI", setJepiData);
    } else {
      setJepiData([]); // Clear the data if unchecked
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareWithJEPI, currentUser]);

  useEffect(() => {
    if (compareWithQYLD) {
      fetchBenchmarkData("QYLD", setQyldData);
    } else {
      setQyldData([]); // Clear the data if unchecked
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareWithQYLD, currentUser]);

  const fetchBenchmarkData = async (benchmarkSymbol, setBenchmarkDataFunc) => {
    try {
      const uid = currentUser
        ? currentUser.uid
        : "QzjwCO39HKbRJzVr04CnWEuvMf72";

      const response = await fetch(
        //`http://localhost:5000/fetchBenchmark?symbol=${benchmarkSymbol}&userId=${uid}`
        `https://us-central1-income-with-options.cloudfunctions.net/api/fetchBenchmark?symbol=${benchmarkSymbol}&userId=${uid}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const tmpBenchmarkData = convertData(data);
      tmpBenchmarkData.__dataIntents = {
        value: [`SeriesTitle/${benchmarkSymbol}`],
      };
      setBenchmarkDataFunc(tmpBenchmarkData);
    } catch (error) {
      console.error(`Error fetching ${benchmarkSymbol} data:`, error);
    }
  };

  useEffect(() => {
    setChartData([
      historicalValues,
      ...(spyData.length > 0 ? [spyData] : []),
      ...(jepiData.length > 0 ? [jepiData] : []),
      ...(qyldData.length > 0 ? [qyldData] : []),
    ]);
  }, [historicalValues, spyData, jepiData, qyldData]);

  useEffect(() => {
    // Ensure both values are set and greater than 0
    if (previousDayValue > 0 && currentPortfolioValue > 0) {
      const dollarDiff = currentPortfolioValue - previousDayValue;
      setDollarDifferenceFromPreviousDay(dollarDiff);

      const percentDiff = (dollarDiff / previousDayValue) * 100;
      setPercentDifferenceFromPreviousDay(percentDiff);
    }
  }, [previousDayValue, currentPortfolioValue]);

  const formatDifference = (dollarDiff, percentDiff) => {
    if (dollarDiff === null || percentDiff === null) return ""; // Return empty string if either value is null

    const arrow = dollarDiff >= 0 ? "↑" : "↓"; // Choose arrow based on dollarDiff
    const color = dollarDiff >= 0 ? "green" : "red"; // Choose color based on dollarDiff
    const formattedDollarDiff = Math.abs(dollarDiff).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const formattedPercentDiff = Math.abs(percentDiff).toFixed(2); // Format the absolute percent difference

    return (
      <span style={{ color: color }}>
        {arrow} ${formattedDollarDiff} ({formattedPercentDiff}%) today
      </span>
    );
  };

  return (
    <div>
      {portfolio && (
        <div className="portfolio">
          <div className="header2">
            <h1 className="h-1">Home</h1>
          </div>
          <div className="content2">
            <div className="leftDiv">
              <h1 className="heading-2">
                $
                {currentPortfolioValue.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h1>
              {dollarDifferenceFromPreviousDay !== null &&
                percentDifferenceFromPreviousDay !== null && (
                  <p className="margin-left-4">
                    {formatDifference(
                      dollarDifferenceFromPreviousDay,
                      percentDifferenceFromPreviousDay
                    )}
                  </p>
                )}
              <div>
                {historicalValues.length > 0 && (
                  <PortfolioLineChart data={chartData} />
                )}
              </div>
              <div className="checkbox-container">
                <Typography variant="h6" sx={{ marginRight: 2 }}>
                  Compare with:
                </Typography>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ marginRight: "-6px" }} // Adjust these values as needed
                        checked={compareWithJEPI}
                        onChange={(e) => setCompareWithJEPI(e.target.checked)}
                      />
                    }
                    label="JEPI"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ marginRight: "-6px" }}
                        checked={compareWithSPY}
                        onChange={(e) => setCompareWithSPY(e.target.checked)}
                      />
                    }
                    label="SPY"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ marginRight: "-6px" }}
                        checked={compareWithQYLD}
                        onChange={(e) => setCompareWithQYLD(e.target.checked)}
                      />
                    }
                    label="QYLD"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="rightDiv">
              <div className="left-right-4">
                <h3>
                  Cash Balance: $
                  {portfolio.cashBalance.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h3>
                <StockPositionsTable
                  data={mergedStockData}
                />
                <OptionPositionsTable
                  data={mergedOptionData}
                  title="Option Positions"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
