// AuthModal.js
import React, { useContext } from 'react';
import './AuthModal.css';
import Register from './Register';
import GoogleSignIn from '../components/GoogleSignIn';
import { AuthContext } from './AuthContext';
import { ClipLoader } from 'react-spinners';

const AuthModal = ({ onClose }) => {
    const { savePositionsLoading } = useContext(AuthContext);

    console.log('Is loading:', savePositionsLoading);

    return (
        <div className="auth-modal">
            <div className="auth-modal-content">
                <h3>Welcome to IncomeWithOptions</h3>
                <p style={{ fontSize: '20px' }}>
                    Sign up below to start tracking your option selling portfolio in real time.
                </p>
                <span className="close" onClick={onClose}>&times;</span>
                <div className="register-container">
                    <Register />
                </div>
                <GoogleSignIn />
                <div>
                    {savePositionsLoading &&
                        <div>
                            <h4 style={{ margin: '16px 0' }}>Loading...</h4>
                            <ClipLoader size={30} color={"#123abc"} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default AuthModal;

