import React, { useState, useEffect, createContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [hasPositionsSaved, setHasPositionsSaved] = useState(false);
  const [isNewAccountRegistered, setIsNewAccountRegistered] = useState(false);
  const [savePositionsLoading, setSavePositionsLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    
    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, hasPositionsSaved, setHasPositionsSaved, isNewAccountRegistered, 
      setIsNewAccountRegistered, savePositionsLoading, setSavePositionsLoading  }}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
