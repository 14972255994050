import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAodhBBbjJIw6nB9kh8JsbHtRhTMdNICb4",
    authDomain: "income-with-options.firebaseapp.com",
    projectId: "income-with-options",
    storageBucket: "income-with-options.appspot.com",
    messagingSenderId: "653319411824",
    appId: "1:653319411824:web:5b80349373fa28c8dbfcb5",
    measurementId: "G-8L35YYT5FL"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  
  export { auth };