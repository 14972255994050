import React from 'react';
import { auth } from '../firebaseConfig';
import './AuthStyles.css'


function LogoutButton() {
  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return <button onClick={handleLogout}>Logout</button>;
}

export default LogoutButton;
