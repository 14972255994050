import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './BlogPost.css'
import { useParams } from 'react-router-dom';

const GHOST_API_URL = 'https://income-with-options.ghost.io/ghost/api/content/';
const GHOST_API_KEY = '45c1b89ef087d75b2d94eb9a1a';

function BlogPost({ match }) {
    const [post, setPost] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`${GHOST_API_URL}/posts/slug/${slug}`, {
                    params: {
                        key: GHOST_API_KEY
                    }
                });

                setPost(response.data.posts[0]);
            } catch (error) {
                console.error('An error occurred while fetching the post:', error);
            }
        };

        fetchPost();
    }, [slug]);

    if (!post) return <div>Loading...</div>;

    return (
        <div className="blog-post">
            {post.feature_image && <img src={post.feature_image} alt={post.title} className="feature-image" />}
            <div className="blog-body">
                <h1>{post.title}</h1>
                <span class="post-date">{new Date(post.published_at).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</span>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
        </div>
    );
}

export default BlogPost;
