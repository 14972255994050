import React, { createContext } from 'react';
import { getFirestore } from 'firebase/firestore';

export const FirestoreContext = createContext(null);

export const FirestoreProvider = ({ children }) => {
  const db = getFirestore();

  return (
    <FirestoreContext.Provider value={db}>
      {children}
    </FirestoreContext.Provider>
  );
};
