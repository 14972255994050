import React from 'react';
import aris_photo from '../components/assets/aris_photo_2.jpg'
import './About.css'

const About = () => {
    return (
        <div className="about">
            <h1 className="h-1">About</h1>
            <div className="overlap">
                <img className="aris_photo" src={aris_photo} alt="Aris_photo"/>
                <p className="overlap_text_about">
                <a href="https://www.linkedin.com/in/aris-vinsant-960037138/">Aris Vinsant</a> is the Chief Investment Officer at Concept IV Capital, Inc and the founder of incomewithoptions.com.
                    <br />
                    <br />
                            As CIO of Concept IV, Aris helps investors maximize their risk-adjusted returns
                            while collecting monthly cash flow through option premiums and dividends. 
                    <br />
                    <br />
                            Aris holds a Bachelor’s degree in Computer Science from the University of Kansas and has spent his career
                            working in various technology roles in the financial and automotive industries.
                    <br />
                    <br />
                            This website is for informational purposes only and should not be relied upon for any investment or trading decisions. Aris will never tell you to buy or sell a specific security unless you are his client.
                    </p>
            </div>
        </div>
    );
};

export default About;