import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Blog.css";
import ClipLoader from "react-spinners/ClipLoader";

const GHOST_API_URL = "https://income-with-options.ghost.io/ghost/api/content/";
const GHOST_API_KEY = "45c1b89ef087d75b2d94eb9a1a";

function Blog() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPosts() {
      try {
        setLoading(true);
        const response = await axios.get(`${GHOST_API_URL}/posts/`, {
          params: {
            key: GHOST_API_KEY,
            include: "authors",
          },
        });
        setPosts(response.data.posts);
      } catch (error) {
        console.error("Error fetching blog posts", error);
      } finally {
        setLoading(false);
      }
    }

    fetchPosts();
  }, []);

  return (
    <div className="Blog">
      <h1 className="h-1">Blog</h1>
      <div className="post-container">
        {loading ? (
          // Display ClipLoader while loading
          <ClipLoader size={60} color={"#123abc"} />
        ) : (
          // Render posts once loading is false
          posts.map((post) => (
            <a href={`/post/${post.slug}`} className="post-card">
              <img
                src={post.feature_image}
                alt={post.title}
                className="post-image"
              />
              <div className="post-details">
                <span className="post-date">
                  {new Date(post.published_at).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </span>
                <h2 className="post-title">{post.title}</h2>
                <div className="post-author">
                  <img
                    src={post.primary_author.profile_image}
                    alt={post.primary_author.name}
                    className="author-image"
                  />
                  <span className="author-name">
                    {post.primary_author.name}
                  </span>
                </div>
              </div>
            </a>
          ))
        )}
      </div>
    </div>
  );
}

export default Blog;
